<template>
<!-- 版本通知 -->
  <div>
    <el-breadcrumb separator="">
      <el-breadcrumb-item class="sptor">版本管理</el-breadcrumb-item>
      <el-breadcrumb-item class="sptor isactive">版本通知</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="setting_main_bg">
      <div class="unit_cont_box">
        <div class="iconfont iconjia settingAdd" @click="handleUserAdd()"><span>新增</span></div>
          <div class="flexAC ut_top">
            <el-input v-model="vcon"  placeholder="请输入通知内容" clearable class="contInput"></el-input>
            <el-date-picker
              v-model="time"
              value-format="yyyy-MM-dd HH:mm"
              type="datetimerange"
              format= "yyyy-MM-dd HH:mm"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              class="timePicker">
            </el-date-picker>
            <el-button class="settingSearch" @click="handleSearch()">搜索</el-button>
            <el-button class="settingReset" @click="handleReset()">重置</el-button>
          </div>
        </div>
      <div class="user_table">
        <el-table
          stripe
          :data="dataList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="通知内容"
            show-overflow-tooltip
            prop="vcon"
            width="1000">
          </el-table-column>
          <el-table-column
            label="通知时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
            label="发送状态"
            prop="visok">
            <template slot-scope="scope">
              <img src="../../assets/img/send.png"  v-if="scope.row.visok == 1" style="cursor: pointer;" @click="handleSend(scope.row)"/>
              <span v-if="scope.row.visok == 2">已发送</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[size]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
    <!-- 新增弹窗 -->
    <el-dialog title="版本新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="fromRule" ref="addFrom">
        <el-form-item label="通知内容(更新时间)" prop="time" >
          <el-date-picker
            style="width: 500px;"
            v-model="addfrom.time"
            value-format="yyyy-MM-dd HH:mm"
            format= "yyyy-MM-dd HH:mm"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="timePicker">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知角色"  prop="vrold" style="margin: 30px 0;">
          <el-select v-model="addfrom.vrold" placeholder="角色" multiple style="width: 500px;">
            <el-option
              v-for="item in roleList"
              :key="item.index"
              :label="item.rolename"
              :value="item.roleid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="addFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 编辑弹窗 -->
    <el-dialog title="版本编辑" :visible.sync="editFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editfromRule" ref="editFrom">
        <el-form-item label="通知内容(更新时间)" prop="time" >
          <el-date-picker
            style="width: 500px;"
            v-model="editfrom.time"
            value-format="yyyy-MM-dd HH:mm"
            format= "yyyy-MM-dd HH:mm"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="timePicker">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知角色"  prop="vrold" style="margin: 30px 0;">
          <el-select v-model="editfrom.vrold" placeholder="角色" multiple style="width: 500px;">
            <el-option
              v-for="item in roleList"
              :key="item.index"
              :label="item.rolename"
              :value="item.roleid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="巡检计划详情" :visible.sync="detailShow" width="800px" center class="gyDialog unit_addDialog">
      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">通知基本详情</p>
          </div>
          <div class="flexCB">
            <p class="udiIP">发送状态：
              <span v-if="detailInfo.visok == 1"  style="color: #195CF7;">未发送</span>
              <span v-if="detailInfo.visok == 2">已发送</span>
            </p>
            <p class="udiIP">通知时间：{{ detailInfo.creatime }}</p>
          </div>
            <p class="udi_p">通知内容：{{ detailInfo.vcon }}</p>
        </div>
      </div>

      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">通知角色</p>
          </div>
          <div class="udi_c" v-for="(item, index) in detailInfo.vroldname" :key="index">
            <div class="udi_r"><span>{{item.vroldname}}</span></div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addVersioNotice, updateVersioNotice, sendVersioNotice, getVersioNotice } from '@/api/version.js'
import { getRole } from '@/api/user.js'

export default {
  data () {
    return {
      activeName: 'first',
      vcon: '',
      page: 1,
      size: 10,
      total: 0,
      dataList: [],
      baptype: '',
      baptypeList: [],
      startTime: '',
      endTime: '',
      time: [],
      roleList: [],
      addFromShow: false,
      addfrom: {
        vrold: '',
        vstime: '',
        vetime: ''
      },
      fromRule: {
        time: [
          { required: true, message: '请选择时间段', trigger: 'bulr' }
        ],
        vrold: [
          { required: true, message: '请选择角色', trigger: 'bulr' }
        ]
      },
      editFromShow: false,
      editfrom: {},
      editfromRule: {
        time: [
          { required: true, message: '请选择时间段', trigger: 'bulr' }
        ],
        vrold: [
          { required: true, message: '请选择角色', trigger: 'bulr' }
        ]
      },
      detailShow: false,
      detailInfo: {}
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.time.length !== 0 && this.time !== null) {
        this.startTime = this.time[0]
        this.endTime = this.time[1]
      }
      const params = {
        vcon: this.vcon,
        page_index: this.page,
        page_size: this.size,
        stime: this.startTime,
        etime: this.endTime
      }
      getVersioNotice(params).then(res => {
        if (res.status === '1') {
          this.dataList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.dataList = []
          this.total = 0
        }
      })
    },
    getInit () {
      // 查询角色
      getRole({ roletype: '3' }).then(res => {
        if (res.status === '1') {
          if (res.data !== undefined || res.data!== null) {
            this.roleList = res.data
          } else {
            this.roleList = []
          }
        } else {
          this.roleList = []
        }
      })
    },
    // 新增
    handleUserAdd () {
      this.addFromShow = true
      this.getInit()
    },
    addFromConfirm () {
      let sTime = ''
			let eTime = ''
			if (this.addfrom.time != null) {
				sTime = this.addfrom.time[0]
				eTime = this.addfrom.time[1]
			}
      let vrold = this.addfrom.vrold.join(',')
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          vstime: sTime,
          vetime: eTime,
          vrold: vrold
        }
        addVersioNotice(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
    // 编辑
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = {...row}
      this.getInit()
      if (this.editfrom.vrold.indexOf(',') != -1) {
				this.editfrom.vrold = this.editfrom.vrold.split(',')
			} else {
				this.editfrom.vrold = [this.editfrom.vrold]
			}
      this.editfrom.time = [this.editfrom.stime, this.editfrom.etime]
    },
    editFromConfirm () {
      let sTime = ''
			let eTime = ''
			if (this.editfrom.time != null) {
				sTime = this.editfrom.time[0]
				eTime = this.editfrom.time[1]
			}
      let vrold = this.editfrom.vrold.join(',')
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          vstime: sTime,
          vetime: eTime,
          vrold: vrold,
          vid: this.editfrom.vid
        }
        updateVersioNotice(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    editFromCancel () {
      this.$refs.editFrom.resetFields()
      this.editFromShow = false
    },
    // 发送
    handleSend (row) {
      sendVersioNotice({vid: row.vid}).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 详情
    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = row
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.vcon = ''
      this.time = []
      this.startTime = ''
      this.endTime = ''
      this.init()
    },
  }
}
</script>

<style scoped>
.ud_left{
  width: 700px;
}
.udi_p,.udi_s,.udiS,.udiIP{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  font-size: 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.udiIP{
  width: 48%;
}
.udi_s{
  width: 24%;
  margin-right: 1%;
}
.udiS{
  width: 49%;
  margin-right: 1%;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.udi_c {
  float: left;
  display: flex;
  width: 160px;
  height: 40px;
  background: #F6F9FF;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 9px 40px;
  font-size: 16px;
  box-sizing: border-box;
}
.isactive >>> .el-breadcrumb__inner {
  color: #196DF7;
}
</style>
